/* eslint-disable */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import authReducer, { initialState } from '../reducers/loginReducer';

export const LoginContext = createContext();

const LoginProvider = ({ children }) => {
  const useLoginContext = useReducer(authReducer, initialState);
  return (
    <LoginContext.Provider value={[...useLoginContext]}>
      <ContextDevTool context={LoginContext} id="LoginContext" displayName="Login Context" />
      {children}
    </LoginContext.Provider>
  );
};

LoginProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default LoginProvider;
