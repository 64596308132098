/* eslint-disable */
export const API_EVENTS = {
  REQUEST: 'REQUEST',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  RESET: 'RESET',
};

export const REVENUE_CATEGORIES_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const CLASS_CATEGORIES_EVENTS = {
  ADD_REQUEST: 'ADD_REQUEST',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAIL: 'ADD_FAIL',
  REMOVE_REQUEST: 'REMOVE_REQUEST',
  REMOVE_SUCCESS: 'REMOVE_SUCCESS',
  REMOVE_FAIL: 'REMOVE_FAIL',
};

export const NETWORK_EVENTS = {
  NETWORK_CALL_REQUEST: 'NETWORK_CALL_REQUEST',
  NETWORK_CALL_SUCCESS: 'NETWORK_CALL_SUCCESS',
  NETWORK_CALL_FAIL: 'NETWORK_CALL_FAIL',
  NETWORK_CALL_RESET: 'NETWORK_CALL_RESET',
};

export const AUTH_EVENTS = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  LOGIN_RESET: 'LOGIN_RESET',
};

export const TIMEZONE_EVENTS = API_EVENTS;
export const STATES_EVENTS = API_EVENTS;

export const TOAST_TYPE = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export const TOAST_EVENTS = {
  SHOW_NETWORK_CALL_FAIL_TOAST: 'SHOW_NETWORK_CALL_FAIL_TOAST',
  HIDE_NETWORK_CALL_FAIL_TOAST: 'HIDE_NETWORK_CALL_FAIL_TOAST',
  SHOW_MESSAGE_TOAST: 'SHOW_MESSAGE_TOAST',
  HIDE_MESSAGE_TOAST: 'HIDE_MESSAGE_TOAST',
};

//----------------------task-constants-------------------------
export const TASK_EVENT = {
  UPLOAD_TASK: 'UPLOAD_TASK',
  UPLOAD_PROGRESS_TASK: 'HIDE_NETWORK_CALL_FAIL_TOAST',
  UPLOAD_COMPLETE_TASK: 'UPLOAD_COMPLETE_TASK',
  TASK_COMPLETE_RESET: 'TASK_COMPLETE_RESET',
  UPLOAD_FAILED_TASK: 'UPLOAD_FAILED_TASK',
  STARTED: 'STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  SAVED: 'SAVED',
  FAILED: 'FAILED',
  TASK_SAVED_SUCCESSFULLY: 'TASK SAVED SUCCESSFULLY',
  FEW_FAILED: 'FEW FAILED',
  SAVE_FAILED: 'SAVE FAILED',
  SAVE_IN_PROGRESS: 'SAVE IN PROGRESS',
  DRAWER_CLOSE: 'DRAWER_CLOSE',
};
//-------------------------------------------------------------
export const STATUS_EVENTS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  RESET: 'RESET',
};
