/* eslint-disable */
import React, { lazy /* , useEffect */ } from 'react';

import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

import Cookies from 'js-cookie';
// import setPassword from '../screens/Auth/SetPassword/SetPassword';

const Auth = lazy(() => import('../screens/Auth'));
const SetEmail = lazy(() => import('../screens/Auth/setEmail/SetEmail'));
// const Login = lazy(() => import('../screens/Auth/Login'));
const SetPassword = lazy(() => import('../screens/Auth/SetPassword'));
const CreateAccount = lazy(() => import('../screens/Auth/CreateAccount'));
const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
const VerifyPassword = lazy(() => import('../screens/Auth/VerifyPassword'));
const CreatePassword = lazy(() => import('../screens/Auth/CreatePassword'));
const CreateAccountPassword = lazy(() => import('../screens/Auth/CreateAccountPassword'));
const CreateOtherInfo = lazy(() => import('../screens/Auth/CreateOtherInfo'));
const Feedback = lazy(() => import('../screens/Main/Feedback'));
const LoginViaSalesforce = lazy(() => import('../screens/Auth/LoginViaSalesforce'));
const AdminImitatorLogin = lazy(() =>
  import('../screens/Auth/AdminImitatorLogin/AdminImitatorLogin'),
);
// const Splash = lazy(() => import('../screens/Auth/Splash'));
// const ForgotPassword = lazy(() => import('../screens/Auth/ForgotPassword'));
// const ResetPassword = lazy(() => import('../screens/Auth/ResetPassword'));
// const SetPassword = lazy(() => import('../screens/Auth/SetPassword'));
// const TwoFactorAuthentication = lazy(() => import('../screens/Auth/TwoFactorAuthentication'));

const PublicRoute = ({ as: Component, ...props }) => {
  // const [cookies] = useCookies();
  const { attendeeParticipatingId } = useParams();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const [salesforceAuth, setSalesforceAuth] = React.useState(false);

  React.useEffect(() => {
    if (
      Cookies.get('userId') &&
      window.location.href.indexOf('/feedback') === -1 &&
      window.location.href.indexOf('/loginViaSalesforce') === -1
    ) {
      navigate('events-list');
    } else if (window.location.href.indexOf('/loginViaSalesforce') !== -1) {
      setSalesforceAuth(true);
    } else {
      setAuth(false);
    }
  }, [navigate]);

  return salesforceAuth ? <LoginViaSalesforce /> : <Component {...props} />;
};

const authNav = [
  {
    path: '',
    element: <SetEmail />,
  },
  {
    path: 'forgotpassword',
    element: <ForgotPassword />,
  },
  {
    path: 'createAccount',
    element: <CreateAccount />,
  },
  {
    path: 'password',
    element: <SetPassword />,
  },
  {
    path: 'createpassword',
    element: <CreatePassword />,
  },
  {
    path: 'createaccountpassword',
    element: <CreateAccountPassword />,
  },
  {
    path: 'createOtherInfo',
    element: <CreateOtherInfo />,
  },
  {
    path: 'api/verifytoken/:token/:Id',
    element: <VerifyPassword />,
  },
  {
    name: 'Redirect to feedback',
    path: '/:idNumber/feedback',
    element: <Feedback />,
  },
  {
    path: 'adminImitatorLogin',
    element: <AdminImitatorLogin />,
  },
];

const authRoutes = [
  {
    path: '/',
    element: <PublicRoute as={Auth} />,
    children: authNav,
  },
];

export default authRoutes;
