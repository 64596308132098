// import { STATUS_EVENTS } from '../constants/actionTypes';

import { STATUS_EVENTS } from '../constants/actionTypes';

export const initialState = {
  loading: false,
  data: [],
  error: null,
};

const StatusReducer = (state, { type, payload }) => {
  switch (type) {
    // case STATUS_EVENTS.:
    //   return { ...state, loading: true };

    case STATUS_EVENTS.SUCCESS: {
      return { ...state, loading: false, data: payload };
    }

    case STATUS_EVENTS.FAIL:
      return { ...state, loading: false, error: payload };

    case STATUS_EVENTS.RESET:
      return initialState;

    default:
      return state;
  }
};

export default StatusReducer;
