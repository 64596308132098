/* eslint-disable*/
import { createMuiTheme } from '@material-ui/core/styles';
import { size } from 'lodash';

const theme = createMuiTheme({
  // Color style
  palette: {
    primary: {
      main: '#33AFE7',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F88700',
      contrastText: '#fff',
    },
    tertiary: {
      main: '#61B3CD',
      contrastText: '#fff',
    },
  },
  // Fonts style
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: 36,
      fontWeight: 600,
      color: '#33AFE7',
      wordBreak: 'break-word',
      '@media (max-width:768px)': {
        fontSize: 28,
      },
    },
    h2: {
      fontSize: 28,
      fontWeight: 600,
      color: '#33AFE7',
      wordBreak: 'break-word',
      '@media (max-width:768px)': {
        fontSize: 24,
      },
    },
    h3: {
      fontSize: 24,
      fontWeight: 400,
      color: '#33AFE7',
      wordBreak: 'break-word',
      '@media (max-width:768px)': {
        fontSize: 20,
      },
    },
    h4: {
      fontSize: 20,
      fontWeight: 400,
      color: '#33AFE7',
      wordBreak: 'break-word',
      '@media (max-width:768px)': {
        fontSize: 18,
      },
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      color: '#FFFFFF',
      wordBreak: 'break-word',
      '@media (max-width:768px)': {
        fontSize: 16,
      },
    },
    h6: {
      fontSize: 14,
      fontWeight: 700,
      color: '#33AFE7',
      wordBreak: 'break-word',
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      color: '#636365',
      lineHeight: '24px',
      '@media (max-width:768px)': {
        fontSize: 14,
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      color: '#666666',
    },
  },
  // Button style
  // forms style
  // Overrides style - button, forms

  overrides: {
    breakpoints: {
      values: {
        xs: 0,
        sm: 767,
        md: 1000,
        lg: 1024,
        xl: 1920,
      },
    },

    MuiPaper: {
      root: {
        backgroundColor: '#F7F6F5',
        paddingLeft: '0px',
        paddingRight: '0px',
        color: '#4B403A',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },

    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '10px solid rgba(243, 239, 234, 1)',
        boxShadow: '3px 0 5px 0 rgba(0,0,0,0.1)',
      },
    },

    MuiDivider: {
      root: {
        marginTop: '10px',
        marginBottom: '10px',
        borderBottom: '1px solid #979797',
        height: 'auto',
        background: 'none',
      },
    },

    MuiButton: {
      root: {
        borderRadius: 5,
        padding: '10px 35px',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1,
        minWidth: 'auto',
        '@media (max-width:960px)': {
          borderRadius: 10,
          padding: '12px 25px',
          fontSize: 14,
        },
        '@media (max-width:768px)': {
          padding: '12px 15px',
        },
      },
      contained: {
        boxShadow: 'none',
        color: '#FFFFFF',
        backgroundColor: 'rgba(248, 135, 0, 1)',
        minWidth: '120px',
        '&:hover': {
          //you want this to be the same as the backgroundColor above
          backgroundColor: '#F88700 !important',
          boxShadow: 'none',
        },
      },
    },
    MuiButtonBase: {
      root: {
        borderRadius: 5,
        padding: '14px 35px',
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1,
        minWidth: 'auto',
        '@media (max-width:960px)': {
          borderRadius: 10,
          padding: '12px 25px',
          fontSize: 14,
        },
      },
      contained: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(162, 156, 153, 1)',
        minWidth: '120px',
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
        '&:active': {
          backgroundColor: 'rgba(72, 96, 207, 0.1)',
          color: '#3C3F61',
        },
      },
      gutters: {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: 16,
        color: '#33AFE7',
        lineHeight: 1.5,
        '&.$Mui-disabled': {
          color: '#000',
        },
      },
      shrink: {
        fontSize: 22,
        color: '#33AFE7',
        fontWeight: 600,
      },
    },
    MuiListItem: {
      button: {
        /* '&:hover': {
          backgroundColor: '#ffffff!important',
          
        }, */
      },
    },

    MuiOutlinedInput: {
      root: {
        border: '2px solid rgba(162,156,153,0.2)',
        borderRadius: 15,
        backgroundColor: 'rgba(255,255,255,0.8)',
      },
      inputMultiline: {
        borderRadius: 15,
        padding: 15,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
      },
      multiline: {
        padding: 0,
      },
      notchedOutline: {
        borderColor: 'transparent',
      },
    },

    MuiAutocomplete: {
      option: {
        '&:hover': {
          backgroundColor: '#ffffff',
        },
        '&$checked': {
          backgroundColor: '#4B403A',
        },
      },
    },

    // Mui: {
    //   Disabled: {
    //     fontSize: 56,
    //     backgroundColor: '#000',
    //   },
    // },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        boxShadow: '0 1px 10px 0 rgba(0,0,0,0.1)',
      },
    },

    MuiInputBase: {
      input: {
        fontSize: 20,
        color: '#58585A',
        backgroundColor: 'transparent',
        borderRadius: 15,
        fontWeight: 400,
        padding: '10px 0',
        '&.$Mui-disabled': {
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&:-webkit-autofill': {
          transition: 'background-color 5000s ease-in-out 0s',
        },
        '@media (max-width:425px)': {
          lineHeight: '22px',
        },
      },
      multiline: {
        padding: '10px 0',
        // border: '2px solid rgba(162,156,153,0.2)',
        // borderBottom: 'none',
        /* fontSize: 20,
        color: '#4B403A', */
        /* backgroundColor: '#fff', */
        /* borderRadius: 0, */
        /* fontWeight: 400, */
        // '&.$Mui-disabled': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.1)',
        // },
      },
      inputMultiline: {
        borderRadius: 0,
      },
    },
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '2px solid rgba(187, 187, 187, 1)',
        },
        '&&&&:after': {
          borderBottom: '2px solid rgba(187, 187, 187, 1)',
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 18,
        fontWeight: 400,
        color: '#4B403A',
      },
    },
    // MuiDialogContent: {
    //   root: {
    //     overflow: 'auto',
    //   },
    // },

    MuiSelect: {
      icon: {
        color: '#F88700',
        right: '5px',
        fontSize: '2.5rem',
        top: 'calc(50% - 18px)',
      },
      outlined: {
        borderRadius: 0,
      },
      select: {
        border: '2px solid #eaeaeb',
        borderRadius: '5px',
        borderRadius: 5,
        padding: '10px 10px',
        border: 'none',
        boxShadow: 'none',
        '&:focus': {
          /* borderRadius: 5, */
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#61B3CD',
      },
    },
    MuiIconButton: {
      root: {
        color: '#F88700',
      },
      colorInherit: {
        color: '#FF7412',
      },
      colorSecondary: {
        color: '#ffffff',
      },
    },

    MuiAvatar: {
      img: {
        width: '100%',
        height: '100%',
      },
    },

    // Right Panel
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#4B403A',
        color: '#ffffff',
      },
    },

    MuiExpansionPanelDetails: {
      root: {
        padding: '10px 0px 10px 0px',
      },
    },

    // Datepicker
    MuiPickersBasePicker: {
      pickerView: {
        width: 'auto',
        height: 'auto',
        maxWidth: 'auto',
        minWidth: 'auto',
        minHeight: 'auto',
        padding: '10px 20px 10px 20px',
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: '15px',
        marginBottom: '30px',
      },
    },

    // Chip

    MuiChip: {
      root: {
        boxShadow: '0px 0 5px rgba(0,0,0,0.3)',
      },
      sizeSmall: {
        fontSize: '16px',
      },
    },

    // Switch
    /* MuiSwitch: {
      root: {
        width: 41,
        height: 22,
        padding: 0,
        display: 'flex',
      },

      switchBase: {
        padding: 4,
        color: '#A29C99',
        '&$checked': {
          transform: 'translateX(20px)',
          color: '#FF7412!important',

          '& + $track': {
            opacity: 1,
            backgroundColor: '#fff!important',
            borderColor: '#FF7412',
            color: '#FF7412!important',
          },
        },
      },

      thumb: {
        width: 13,
        height: 13,
        boxShadow: 'none',
      },

      track: {
        border: '2px solid #A29C99',
        borderRadius: 30 / 2,
        opacity: 1,
        backgroundColor: '#fff',
      },
    }, */

    // Overrides style - modal dialog
    MuiDialog: {
      paper: {
        background: '#fff',
      },
    },
    MuiDialogTitle: {
      root: {
        margin: 0,
        padding: '16px',
        textAlign: 'center',
      },
    },

    MuiDialogContent: {
      root: {
        overflow: 'auto',
        overflowX: 'hidden',
        paddingTop: '0px',
        paddingBottom: '24px',
        paddingLeft: '24px',
        paddingRight: '24px',
        '@media (max-width:425px)': {
          paddingLeft: '15px',
          paddingRight: '15px',
        },
      },
    },

    MuiDialogActions: {
      spacing: {
        paddingTop: '16px',
        paddingBottom: '30px',
        paddingLeft: '16px',
        paddingRight: '16px',
        justifyContent: 'center',
        '@media (max-width:425px)': {
          paddingLeft: '15px',
          paddingRight: '15px',
        },
      },
    },

    // Chat

    MuiListItemText: {
      root: {
        width: 'inherit',
      },
      primary: {
        fontSize: '13px',
        fontWeight: 'bold',
      },
    },

    // Overrides style - material ui data table

    MuiTableCell: {
      root: {
        borderBottom: '1px solid #D0D0D0',
        wordBreak: 'break-word',
        padding: '12px',
        '&:last-child': {
          /* paddingRight: '12px!important', */
          /* width: '96px!important', */
          width: '15% !important',
        },
      },

      head: {
        padding: '18px 10px',
        backgroundColor: '#F1F1F1 !important',
        color: '#33AFE7',
        fontWeight: 'bold',
        fontSize: 14,
        whiteSpace: 'nowrap',
        /* border: 'none', */
      },

      body: {
        color: '#666666',
      },
    },

    MuiTableRow: {
      root: {
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#F1F1F1',
        },
      },
      footer: {
        backgroundColor: '#f3efea',
        '&:hover': {
          backgroundColor: '#f3efea',
        },
      },
    },

    MuiTablePagination: {
      select: {
        backgroundColor: 'transparent',
        border: '0px solid #c4c4c4',
        boxShadow: '0px 0px 0px rgba(162,156,153,0.7)',
        lineHeight: '20px',
      },

      root: {
        '&:last-child': {
          width: '100%!important',
        },
      },
    },
  },
});

export default theme;
