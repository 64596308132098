/*eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import theme from './theme';
import './index.css';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

// import useStyles from './Dashboard.style';
import * as serviceWorker from './serviceWorker';

// if ('serviceWorker' in navigator) {
// navigator.serviceWorker
//   .register("./firebase-messaging-sw.js")
//   .then(function(registration) {
//     console.log("Registration successful, scope is:", registration.scope);
//   })
//   .catch(function(err) {
//     console.log("Service worker registration failed, error:", err);
//   });
// }

// <ThemeProvider theme={theme}>
//   <I18nextProvider i18n={i18n}>
//     <App />
//   </I18nextProvider>
// </ThemeProvider>,

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
