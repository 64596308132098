/* eslint-disable */

import React, { lazy } from 'react';
const LoginViaSalesforce = lazy(() => import('../screens/Auth/LoginViaSalesforce'));
const Dashboard = lazy(() => import('../screens/Main/Dashboard'));
const UpcomingEvents = lazy(() => import('../screens/Main/Events/UpcomingEvents'));
const ProgramEventDetails = lazy(() => import('../screens/Main/Events/ProgramEventDetails'));
const ReviewProgramEvent = lazy(() => import('../screens/Main/Events/ReviewProgramEvent'));
const ModuleDetails = lazy(() => import('../screens/Main/Module'));
const TrainerDashboard = lazy(() => import('../screens/Main/TrainerDashboard'));
const UserProfile = lazy(() => import('../screens/Main/UserProfile'));
const Feedback = lazy(() => import('../screens/Main/Feedback'));
export const ChildRoutes = [
  {
    name: 'dashboard',
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    name: 'userProfile',
    path: 'userprofile',
    element: <UserProfile />,
  },
  {
    name: 'Upcoming Events',
    path: 'events-list',
    element: <UpcomingEvents />,
  },

  {
    name: 'Program Event Details',
    path: '/program/:idNumber',
    element: <ProgramEventDetails />,
  },
  {
    name: 'Module Details',
    path: '/:eventId/module-details/:moduleId',
    element: <ModuleDetails />,
  },
  {
    name: 'View Program Event Reviews',
    path: '/:idNumber/postevent',
    element: <ReviewProgramEvent />,
  },
  {
    name: 'View Program Event Reviews',
    path: 'trainer-dashboard',
    element: <TrainerDashboard />,
  },
  {
    name: 'Login Via Salesforce',
    path: 'loginViaSalesforce',
    element: <LoginViaSalesforce />,
  },
];

export default ChildRoutes;
