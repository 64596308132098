/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
/* eslint-disable  */
import React, { lazy, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
// import appNav from './appNav';
import Loader from '../components/Loader/Loader';
import ChildRoutes from './childRoutes';

const Main = lazy(() => import('../screens/Main'));

const PrivateRoute = ({ as: Component, ...props }) => {
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(true);
  // console.log(Cookies.get('userId'))

  useEffect(() => {
    if (Cookies.get('userId')) {
      setAuth(true);
    } else {
      if (window.location.href.indexOf('/loginViaSalesforce') !== -1) {
        return;
      } else if (
        window.location.href.indexOf('/program') == -1 &&
        window.location.href.indexOf('/postevent') == -1
      ) {
        setAuth(false);
        localStorage.setItem('afterLoginRedirect', window.location.href);
        navigate('/');
      }
    }
    // if (false) {
    //   navigate('../');
    //   setAuth(false);
    // } else {
    //   setAuth(true);
    // }
  }, [navigate]);

  return auth ? <Component {...props} /> : <Loader />;
};

const appRoutes = [
  {
    path: '/',
    element: <PrivateRoute as={Main} />,
    children: ChildRoutes,
  },
];

export default appRoutes;
