/* eslint-disable */
import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import ContextDevTool from 'react-context-devtool';
import authReducer, { initialState } from '../reducers/StatusReducer';

export const StatusContext = createContext();

const StatusProvider = ({ children }) => {
  const useStatusContext = useReducer(authReducer, initialState);
  return (
    <StatusContext.Provider value={[...useStatusContext]}>
      <ContextDevTool context={StatusContext} id="StatusContext" displayName="Status Context" />
      {children}
    </StatusContext.Provider>
  );
};

StatusProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StatusProvider;
